import React from "react";
import styled from "@emotion/styled";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import MainLayout from "../components/layout/main-layout";
import { Header, HeaderImage } from "../components/layout/header";
import Certificates from "../components/history/certificates";
import OurProducts from "../components/products/our-products";
import BackgroundImage from "../images/fundo-historia.jpg";
import HistoryText from "../components/history/history-text";

const HistoryBlock = styled.div`
  background-color: #3a4648;
  padding: 20px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    padding: 40px 80px;
  }

  @media screen and (min-width: 1400px) {
    padding: 44px 0;
  }
`;

const CertificatesBlock = styled.div`
  background-color: var(--color3);
  padding: 40px 0;
  font-size: 24px;
  text-align: center;
  line-height: 32px;
`;

const HistoryDescription = styled.div`
  margin: 0 auto;
  max-width: 967px;
  letter-spacing: 0.57px;
  line-height: 36px;
`;

const HistoriaPage = () => (
  <Layout>
    <SEO
      title="História"
      keywords={["história", "certificações", "naturalfa"]}
      image={BackgroundImage}
    />

    <HeaderImage image={BackgroundImage}>
      <MainLayout>
        <Header
          title="A nossa história.."
          subtitle="Desde outubro de 2016, a produzir os melhores e mais saborosos cogumelos."
        />
      </MainLayout>
    </HeaderImage>
    <HistoryBlock>
      <MainLayout>
        <HistoryDescription>
          <HistoryText />
        </HistoryDescription>
      </MainLayout>
    </HistoryBlock>

    <CertificatesBlock>
      <MainLayout>
        <Certificates />
      </MainLayout>
    </CertificatesBlock>

    <OurProducts />
  </Layout>
);

export default HistoriaPage;
