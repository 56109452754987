import React from "react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { H1 } from "../../styles/typography";
import PDFLogo from "../logos/pdf-logo";

const CertificatesContainer = styled.div`
  color: var(--color2);
  text-align: left;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100%;
  padding: 20px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 50% 50%;
    padding: 75px 80px;
  }

  @media screen and (min-width: 1400px) {
    padding: 75px 0;
  }
`;

const Title = styled(H1)`
  margin: 0;
  color: #000;
`;

const Description = styled.p`
  font-size: 19px;
  letter-spacing: 0.45px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CertImage = styled(GatsbyImage)`
  height: 300px;
  border-radius: 10px;
`;

const Strong = styled.span`
  font-weight: bold;
  font-size: 21px;
  letter-spacing: 0.45px;
`;

const CertificaLinkContainer = styled.div`
  display: flex;
  font-weight: bold;
`;

const CertificaLink = styled.a`
  margin-left: 12px;
`;

const Certificates = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "shitake-description.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <CertificatesContainer>
      <Column>
        <Title>Certificações</Title>
        <Description>
          Cumprimos os requisitos da <Strong>NATURALFA</Strong>, para a
          obtenção, preparação e comercialização dos nossos produtos em{" "}
          <Strong>Modo de Produção Biológico.</Strong>
        </Description>

        <CertificaLinkContainer>
          <span>Certificado</span>
          <CertificaLink
            href="/certificado_2021_2022.pdf"
            target="_blank"
            title="Certificado"
          >
            <PDFLogo />
          </CertificaLink>
        </CertificaLinkContainer>
      </Column>
      <Column>
        <CertImage
          image={data.file.childImageSharp.gatsbyImageData}
          alt="Cogumelos"
        />
      </Column>
    </CertificatesContainer>
  );
};

export default Certificates;
